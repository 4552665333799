/*eslint-disable */
import {
  mapGetters
} from 'vuex'; 
export default {
  name: 'doctor-specialties',
  components: {},
  props: [],
  data () {
    return {
      doctorsFiltersList:{},      
      doctorsFiltersListResults:[]
    }
  },
  computed: {
    ...mapGetters([
      'doctorsFiltersListGetters'
    ]),
    getDoctorsFiltersListMethod() {
      return this.doctorsFiltersList = this.$store.getters.doctorsFiltersListGetters.data
    },
    getDoctorsFiltersListStateMethod(){
      if(this.doctorsFiltersList != undefined){
        this.doctorsFiltersListResults = this.doctorsFiltersList.data
      }
      return;
    }
  },
  mounted () {
    this.getDoctorsFiltersListMethod
    this.getDoctorsFiltersListStateMethod
  },
  methods: {

  },
  watch:{
    getDoctorsFiltersListMethod() {
      return this.$store.getters.doctorsFiltersListGetters
    },
    getDoctorsFiltersListStateMethod(){
      if(this.doctorsFiltersList != undefined){
        this.doctorsFiltersListResults = this.doctorsFiltersList.data
      }
      return;
    }
  }
}


